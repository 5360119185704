






































































































import './ProjectTeam.scss';
import './ProjectTeamAdaptive.scss';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IMemberInfo } from '@store/modules/project-team/interfaces/Interfaces';
import { ProjectTeamActions, ProjectTeamMutations } from '@store/modules/project-team/Types';
import { IViewMode } from '@/interfaces/IViewMode';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { AboutProjectActions } from '@store/modules/about-project/Types';
import { ProjectActions } from '@/store/modules/project/Types';
import { IJuristicEntity } from '@store/modules/about-project/Interfaces';
import { IRespond } from '@store/modules/project-responds/interfaces/Interfaces';
import { ProjectRespondsActions } from '@store/modules/project-responds/Types';
import { UserActionTypes } from '@store/modules/user/Types';
import { IUser } from '@store/modules/user/Interfaces';

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSProjectTeam = namespace('storeProjectTeam');
const NSAboutProject = namespace('storeAboutProject');
const NSDocuments = namespace('storeProjectDocuments');
const NSResponds = namespace('storeProjectResponds');

@Component({
    name: 'ProjectTeam',

    components: {
        BaseBoard: () => import('@components/BaseBoard'),
        MemberCard: () => import('./components/MemberCard.vue'),
        RolesPopup: () => import('./components/RolesPopup.vue'),
        BasePreloader: () => import('@components/BasePreloader'),
        BaseCardShell: () => import('@components/BaseCardShell'),
        AddMemberPopup: () => import('./components/AddMemberPopup.vue'),
        ProjectTeamHeader: () => import('./components/ProjectTeamHeader.vue'),
        PopupConfirmation: () => import('@/components/popups/PopupConfirmation.vue'),
    },
})
export default class ProjectTeam extends Vue {
    @NSUser.Action(UserActionTypes.A_REFRESH_TOKEN) refreshToken!: () => Promise<IUser>
    @NSProject.Getter('projectId') projectId!: number;
    @NSProject.Action(ProjectActions.A_GET_MEMBER_ROLES) getMemberRoles!: (appId: number) => Promise<void>;
    @NSProjectTeam.Getter('projectTeamLoaded') projectTeamLoaded!: boolean;
    @NSProjectTeam.Getter('filters') filters!: any[];
    @NSProjectTeam.Getter('filtersApply') filtersApply!: boolean;
    @NSProjectTeam.Getter('daconMembers') daconMembersStore!: IMemberInfo[];
    @NSProjectTeam.Getter('smartcomMembers') smartcomMembersStore!: IMemberInfo[];
    @NSProjectTeam.Getter('deactivatedMembers') deactivatedMembers!: IMemberInfo[];
    @NSProjectTeam.Getter('currentViewMode') currentViewMode!: IViewMode | null;
    @NSProjectTeam.Mutation(ProjectTeamMutations.M_SET_PROJECT_TEAM_LOADED) setProjectTeamLoaded!: (loadedState: boolean) => void;
    @NSProjectTeam.Action(ProjectTeamActions.A_GET_MEMBER_RIGHTS) getMemberRights!: (projectId: string | number) => Promise<void>;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_CATEGORY_LIST) getCategoryList!: (projectId: number | string) => void;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_PACK_PD_OPTIONS) getPackPDList!: (id: number) => void;
    @NSAboutProject.Action(AboutProjectActions.A_GET_JURISTIC_ENTITIES) getJuristicEntities!: (projectId: number | string) => Promise<any>;
    @NSAboutProject.Action(AboutProjectActions.A_SET_JURISTIC_ENTITIES) setJuristicEntities!: (entity: IJuristicEntity) => void;
    @NSResponds.Getter('projectResponds') responds!: IRespond[];
    @NSResponds.Action(ProjectRespondsActions.A_GET_PROJECT_ALL_ORDERS) getProjectAllOrders!: (id: number) => Promise<void>;

    informationPopupShow: boolean = false;
    informationPopupText: string = '';
    showInformationPopupDuration: number = 2500;
    filteredDaconMembersData: any[] = this.daconMembers;
    filteredJuristicMembersData: any[] = this.smartcomMembers;
    filteredDeactivatedMembersData: any[] = this.deactivatedMembers || [];

    @Watch('projectId')
    watchProjectId() {
        this.setProjectTeamLoaded(false);
        this.checkLoadData();
    }

    @Watch('filters')
    watchFilters() {
        this.setMembersData();
    }

    @Watch('projectTeamLoaded')
    watchProjectTeamLoaded() {
        if (this.projectTeamLoaded) {
            this.setMembersData();
            this.requestsAfterMainDataLoaded();
        }
    }

    mounted() {
        this.onMounted(true);
    }

    get filteredCount(): number {
        if ((this.filteredDaconMembersData && this.filteredDaconMembersData.length)
            || (this.filteredJuristicMembersData && this.filteredJuristicMembersData.length)
            || (this.filteredDeactivatedMembersData && this.filteredDeactivatedMembersData.length)) {
            return this.filteredDaconMembersData.length + this.filteredJuristicMembersData.length + this.filteredDeactivatedMembersData.length;
        }
        return 0;
    }

    get deactivatedMembersIds() {
        return this.deactivatedMembers.map(item => item.uuid);
    }

    get daconMembers() {
        if (this.daconMembersStore) {
            return this.daconMembersStore.filter(item => !this.deactivatedMembersIds.includes(item.uuid));
        }
        return [];
    }

    get smartcomMembers() {
        if (this.smartcomMembersStore) {
            return this.smartcomMembersStore.filter(item => !this.deactivatedMembersIds.includes(item.uuid));
        }
        return [];
    }

    get filteredDeactivatedMembers() {
        return this.deactivatedMembers;
    }

    get isSimpleViewMode() {
        return this.currentViewMode === 'simple';
    }

    get filteredDaconMembersNew() {
        if (this.filtersApply) {
            return this.filteredDaconMembersData;
        }
        return this.daconMembers;
    }

    get filteredSmartcomMembersNew() {
        if (this.filtersApply) {
            return this.filteredJuristicMembersData;
        }
        return this.smartcomMembers;
    }

    get filteredDeactivatedMembersNew() {
        if (this.filtersApply) {
            return this.filteredDeactivatedMembersData;
        }
        return this.deactivatedMembers;
    }

    onMounted(tryRefreshToken = false) {
        if (this.projectTeamLoaded) {
            this.setMembersData();
            this.requestsAfterMainDataLoaded(tryRefreshToken);
        }
        this.getProjectAllOrders(this.projectId);
    }

    checkLoadData() {
        if (!this.responds.length && this.projectId >= 0) {
            this.getProjectAllOrders(this.projectId);
        }
    }

    requestsAfterMainDataLoaded(tryRefreshToken = false): void {
        this.getJuristicEntities(this.projectId)
            .catch((err) => {
                if (tryRefreshToken && err.response?.status === 401) {
                    this.refreshToken().then(() => {
                        this.onMounted();
                    });
                }
            })
            .then((juristicEntity: IJuristicEntity) => this.setJuristicEntities(juristicEntity));
        this.getMemberRoles(this.projectId);
        this.getMemberRights(this.projectId);
        this.getCategoryList(this.projectId);
        this.getPackPDList(this.projectId);
    }

    setMembersData() {
        this.filteredDaconMembersData = this.changeFilteredDaconMembersData('dacon');
        this.filteredJuristicMembersData = this.changeFilteredDaconMembersData('smartcom');
        this.filteredDeactivatedMembersData = this.changeFilteredDaconMembersData('deactivated');
    }

    changeFilteredDaconMembersData(type) {
        const items = type === 'dacon' ? this.daconMembers :  type === 'smartcom' ? this.smartcomMembers : this.deactivatedMembers;
        if (this.filters && this.filters.length) {
            let output: any = items;
            this.filters.forEach((filterObject) => {
                if (filterObject.type === 'checkboxes' || filterObject.type === 'select') {
                    if (filterObject.block === 'status' && filterObject.key === 'status' && filterObject.selected.length) {
                        const outputCopy = JSON.parse(JSON.stringify(output));
                        let outputByStatus: any[] = [];

                        if (filterObject.selected.includes('Портал, активен') && type === 'dacon') {
                            outputByStatus = outputByStatus.concat(outputCopy.filter((member) => member.active));
                        }
                        if (filterObject.selected.includes('Портал, не активен') && type === 'dacon') {
                            outputByStatus = outputByStatus.concat(outputCopy.filter((member) => !member.active));
                        }
                        if (filterObject.selected.includes('Инженерная часть, активен') && type === 'smartcom') {
                            outputByStatus = outputByStatus.concat(outputCopy.filter((member) => member.active));
                        }
                        if (filterObject.selected.includes('Инженерная часть, не активен') && type === 'smartcom') {
                            outputByStatus = outputByStatus.concat(outputCopy.filter((member) => !member.active));
                        }
                        if (filterObject.selected.includes('Не действительны') && type === 'deactivated') {
                            outputByStatus = outputByStatus.concat(this.deactivatedMembers);
                        }
                        output = outputByStatus;
                    }
                    if (filterObject.block === 'company' && filterObject.key === 'entityId' && filterObject.selected.length) {
                        output = output.filter(item => filterObject.selected.includes(item.legalEntity));
                    }
                    if (filterObject.block === 'role' && filterObject.key === 'role.label' && filterObject.selected.length) {
                        output = output.filter(item => item.role && filterObject.selected.includes(item.role.label));
                    }
                }
            });
            return output;
        }
        return items;
    }

    changeMemberSelection(member: IMemberInfo) {
        member.selected = !member.selected;
    }

    popupConfirmApplyRole(role) {
        this.showInformationPopup(`Назначена роль "${role}"`);
    }

    showInformationPopup(text) {
        this.informationPopupShow = true;
        this.informationPopupText = text;

        setTimeout(() => {
            this.informationPopupShow = false;
            this.informationPopupText = '';
        }, this.showInformationPopupDuration);
    }
}
