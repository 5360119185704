var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.projectTeamLoaded)?_c('section',{staticClass:"project-team"},[_c('ProjectTeamHeader',{attrs:{"items":_vm.daconMembers.concat(_vm.smartcomMembers),"filtered-items-length":_vm.filteredCount}}),(_vm.filteredDaconMembersNew.length)?_c('div',{staticClass:"project-team__division"},[_c('h2',{staticClass:"project-team__division-title"},[_vm._v("Портал - "+_vm._s(_vm.filteredDaconMembersNew.length))]),_c('BaseBoard',{class:{'base-board--simple': _vm.currentViewMode},attrs:{"items":_vm.filteredDaconMembersNew},scopedSlots:_vm._u([{key:"board-item",fn:function(ref){
var item = ref.item;
var itemIndex = ref.itemIndex;
return [_c('BaseCardShell',{class:{'base-card-shell--simple': _vm.currentViewMode},attrs:{"index":itemIndex,"selected":item.selected,"checkbox-disabled":!item.role.uuid},on:{"selection-changed":function($event){return _vm.changeMemberSelection(item)}}},[_c('MemberCard',{class:{'member-card--simple': _vm.currentViewMode},attrs:{"part":"portal","is-on-top":itemIndex === 0,"show-column-key":_vm.isSimpleViewMode,"member":item}})],1)]}}],null,false,2097962951)})],1):_vm._e(),(_vm.filteredSmartcomMembersNew.length)?_c('div',{staticClass:"project-team__division"},[_c('h2',{staticClass:"project-team__division-title"},[_vm._v("Инженерная часть - "+_vm._s(_vm.filteredSmartcomMembersNew.length))]),_c('BaseBoard',{class:{'base-board--simple': _vm.currentViewMode},attrs:{"items":_vm.filteredSmartcomMembersNew},scopedSlots:_vm._u([{key:"board-item",fn:function(ref){
var item = ref.item;
var itemIndex = ref.itemIndex;
return [_c('BaseCardShell',{class:{'base-card-shell--simple': _vm.currentViewMode},attrs:{"index":itemIndex,"selected":item.selected,"checkbox-disabled":!item.role.uuid},on:{"selection-changed":function($event){return _vm.changeMemberSelection(item)}}},[_c('MemberCard',{class:{'member-card--simple': _vm.currentViewMode},attrs:{"part":"engineer","is-on-top":itemIndex === 0,"show-column-key":_vm.isSimpleViewMode,"member":item}})],1)]}}],null,false,2876186346)})],1):_vm._e(),(_vm.filteredDeactivatedMembersNew.length)?_c('div',{staticClass:"project-team__division"},[_c('h2',{staticClass:"project-team__division-title"},[_vm._v("Не действителен - "+_vm._s(_vm.filteredDeactivatedMembersNew.length))]),_c('BaseBoard',{class:{'base-board--simple': _vm.currentViewMode},attrs:{"items":_vm.filteredDeactivatedMembersNew},scopedSlots:_vm._u([{key:"board-item",fn:function(ref){
var item = ref.item;
var itemIndex = ref.itemIndex;
return [_c('BaseCardShell',{class:{'base-card-shell--simple': _vm.currentViewMode},attrs:{"index":itemIndex,"selected":item.selected,"checkbox-disabled":!item.role.uuid},on:{"selection-changed":function($event){return _vm.changeMemberSelection(item)}}},[_c('MemberCard',{class:{'member-card--simple': _vm.currentViewMode},attrs:{"part":"engineer","is-on-top":itemIndex === 0,"show-column-key":_vm.isSimpleViewMode,"member":item}})],1)]}}],null,false,2876186346)})],1):_vm._e(),_c('AddMemberPopup',{on:{"successFindMember":function($event){return _vm.showInformationPopup('Заказ на SmartComm успешно создан')},"successInviteMember":function($event){return _vm.showInformationPopup('Письмо с приглашением E-mail успешно отправлено')}}}),_c('PopupConfirmation',{attrs:{"title":"Уведомление","confirm-text":"Закрыть","popup-open":_vm.informationPopupShow},on:{"close":function($event){_vm.informationPopupShow = false}}},[_c('p',{staticClass:"project-team__success-popup-text"},[_vm._v(" "+_vm._s(_vm.informationPopupText)+" ")])]),_c('RolesPopup',{on:{"popup-confirm-apply-role":_vm.popupConfirmApplyRole}})],1):_c('BasePreloader')}
var staticRenderFns = []

export { render, staticRenderFns }